import React from "react";

function Signature({ formData }) {
  const { name, email, job, phone1, phone2 } = formData;

  const signatureHTML = `
      <table cellpadding="0" cellspacing="0" border="0" style="padding: 10px 0; font-size: 12px; color: #A07827;">
        <tr>
          <td style="padding: 40px; vertical-align: middle;">
            <img src="/cavessaodomingos/logo.png" alt="Company Logo" style="width: 150px; height: auto;"/>
          </td>
          <td style="padding: 20px; vertical-align: middle;">
                <strong style="color:#A07827; font-size: 16px; font-family: 'Playfair Display', serif;">${name}</strong><br/>
                <span style="color:#A07827; font-size: 12px; font-family: 'Poppins', sans-serif; font-weight:300">${job}</span><br/><br/>
            <div style="color:#A07827; border-top:1px solid #A07827; border-bottom:1px solid #A07827;padding:10px 0; width:20rem">
             <a href="mailto:${email}" style="color:#A07827; font-size: 12px; font-family: 'Poppins', sans-serif; text-decoration:none; font-weight:500;">${email}</a><br/><br/>
              <a href="tel:${phone1}" style="color:#A07827; font-size: 12px; font-family: 'Poppins', sans-serif; text-decoration:none; font-weight:500;" >${phone1}</a> | <a href="tel:${phone2}" style="color:#A07827; font-size: 12px; font-family: 'Poppins', sans-serif; text-decoration:none; font-weight:500;" >${phone2}</a><br/></br>
              <span style="color:#A07827; font-size: 12px; font-family: 'Poppins', sans-serif; font-weight:500;" >Rua Elpídio Martins Semedo, 45<br/> Ferreiros | 3781-909 Anadia | Portugal</span><br/>
            </div>
            <div style="padding-top: 10px;">
            <a href="https://www.cavesaodomingos.com" style="color:#A07827; font-family: 'Poppins', sans-serif; text-decoration:none; font-weight:500;">www.cavesaodomingos.com</a><br/><br/>
        <a href="https://www.facebook.com/cavesdosolardesaodomingos" style="text-decoration: none;">
          <img src="/cavessaodomingos/fb.png" style="vertical-align: middle; width: 20px; height: 20px;"/>
        </a>
        <a href="https://www.instagram.com/cavesaodomingos/" style="text-decoration: none;">
          <img src="/cavessaodomingos/ig.png" style="vertical-align: middle; width: 20px; height: 20px;"/>
        </a>
         <a href="https://www.linkedin.com/company/caves-s%C3%A3o-domingos/" style="text-decoration: none;">
          <img src="/cavessaodomingos/ld.png" style="vertical-align: middle; width: 20px; height: 20px;"/>
        </a>
         <a href="https://www.youtube.com/channel/UCdSMvLP3CO2lTN6M8wsS5SQ" style="text-decoration: none;">
          <img src="/cavessaodomingos/yt.png" style="vertical-align: middle; width: 20px; height: 20px;"/>
        </a>
      </div>
          </td>
        </tr>
      </table>
`;

  return (
    <div>
      <h2>Generated Signature</h2>
      <div dangerouslySetInnerHTML={{ __html: signatureHTML }}></div>
    </div>
  );
}

export default Signature;
